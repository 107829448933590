import { createBrowserRouter, RouterProvider, createHashRouter } from 'react-router-dom'
import Layout from './layout/layout'
import Careers from './pages/careers/careers';
import Apply from './pages/shared/Components/Apply/Apply/apply';
import Form from './pages/shared/Components/Form/form';
import ApplyForm from './pages/ApplyForm/applyForm';
import NotFound from './pages/NotFound/notFound';

const Routing = () => {
    const AppRouter = window.process ? createHashRouter : createBrowserRouter;

    const routes = AppRouter([
        {
            path: '/',
            element: <Layout> </Layout>,
            children: [
                {
                    path: '/',
                    element: <Careers />
                },
                {
                    path: '/apply',
                    element: <ApplyForm />
                },
                {
                    path: '/*',
                    element: <NotFound />
                }
            ]
        }
    ]);



    return <RouterProvider router={routes} />

}
export default Routing