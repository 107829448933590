import TechIcon from '../src/assets/svg/tech_icon.svg'
import DesignIcon from '../src/assets/svg/design_icon.svg'
import ManagementIcon from '../src/assets/svg/management_icon.svg'
import { JOB_TIME, JOB_STATUS } from './pages/shared/Constants/constants';

export const jobs_data = [
  {
    job_id: "1645",
    title: "C++ Developer",
    location: "Bangalore / IIT Guwahati, India",
    category: "Technology",
    time: JOB_TIME.FULL_TIME,
    status: JOB_STATUS.HIRING_ACTIVELY,
    // experience: "Exp:  3+ Years",
    description: "We are looking for a skilled C++ Developer to join our development team.",
    image_url: TechIcon,
    JD: `
    <h2>About Ziroh Labs</h2>
    <br>
    <p>Ziroh Labs is a Data Security and Privacy Technology startup based in the Bay Area (California), Bangalore, and Canberra.</p>
    <br>
    <p>We work on core Computer Science topics, and we promise you will have a lot of fun. There’s no such thing as work at Ziroh Labs. We love writing code and are looking for those of you who also enjoy it. Compensation is, of course, at par with the industry, along with the perks of having an opportunity to read the best of the papers in computer science, discuss, present, total allowance to think radical and wild (we love failures!), and implementing tech that can change the lives of the people at large.</p>
    <br>
    <p>The scientific and technical team at Ziroh Labs includes award-winning computer scientists, cryptographers, PhDs, and developers in distributed computing, large-scale distributed storage systems, and algorithms. Ziroh Labs is a winner at Cisco Launchpad Cohort 3, Mass Challenge, Israel, Samsung Global Accelerator Program, and NASSCOM Deep Tech club members. Individuals will get opportunities to contribute in all different areas depending on the demand and requirement of the time.</p>
    <br></br> 
    <h2>Job Overview:</h2>
    <br>
    <p>We are looking for a skilled C++ Developer to join our development team.
    In this role, you will develop high-performance applications that directly interface
    with processor hardware, optimise system processes, and ensure smooth
    integration of different components. You should be proficient in algorithm design,
    especially algorithms for implementing mathematical equations, and
    deeply understand C++ programming paradigms.
    </p>
    <br>
    <br>
    <h2>Key Responsibilities:</h2>
    <br>
    <ul>
    <li>Design, develop and maintain C++ code.</li>
    <li>Collaborate with cross-functional teams to define software
    requirements and implement solutions.</li>
    <li>Analyse, optimise and debug code to enhance performance and efficiency.</li>
    <li>Write high-quality, reusable and testable code.</li>
    <li>Conduct code reviews and ensure compliance with coding standards.</li>
    <li>Develop and integrate libraries, APIs and frameworks.</li>
    <li>Troubleshoot, test and deploy applications and systems.</li>
    <li>Stay current with industry best practices and advancements
    in C++ and related technologies.</li>
    <li>Participate in problem-solving sessions and contribute to the
    development of project architectures.</li>
    </ul>
    <br></br>
    <h2>Required Skills & Qualifications:</h2>
    <br>
    <ul>
    <li>Bachelor’s degree in Computer Science, Engineering or related field.</li>
    <li>Proven experience as a C++ Developer (3+ years).</li>
    <li>Strong knowledge of C++ programming, especially memory management.</li>
    <li>Experience with multi-threading, concurrency and performance optimization.</li>
    <li>Familiarity with software development life cycle (SDLC) and version control.</li>
    <li>Strong debugging, problem-solving and analytical skills.</li>
    <li>Experience with development tools such as IDEs (e.g., Visual Studio Code) and
    build systems (e.g., GCC, CMake, Make).</li>
    <li>Familiarity with Linux/Unix-based systems is a plus.</li>
    </ul>
    <br></br>
    <h2>Desired Skills:</h2>
    <br>
    <ul>
    <li>Experience with C++11, C++14, C++17 or C++20 features.</li>
    <li>Exposure to real-time systems or embedded systems.</li>
    <li>Familiarity with Linear Algebra, Probability and Statistics.</li>
    </ul>
    <br></br>
    <h2>Benefits:</h2>
    <br>
    <ul>
    <li>Competitive salary and performance bonus.</li>
    <li>Health insurance coverage for individual and family members.</li>
    <li>Opportunity to work on exciting problems and contribute to innovation.</li>
    <li>Work with a passionate and enthusiastic young team.</li>
    <li>Opportunity for rapid upskilling and career growth.</li>
    <li>Collaborative and inclusive startup work culture.</li>
    <li>5 days of Workcation to any destination outside India for best performers.</li>
    </ul>
    <br>
    </br>
    <h2>Experience:</h2>
    <br>
    <ul>
    <li>3+ Years</li>
    </ul>
    <br></br>
    <h2>Interview Process:</h2>
    <br>
    <ul>
    <li>Meet and Greet</li>
    <li>Coding Round -1</li>
    <li>F2F</li>  
    <li>HR</li>

    </ul>
    <br></br>
    <h2>Expected time for Joining:</h2>
    <br>
    <ul>
    <li>Within 15-30 days post-selection.</li>
    </ul>
    `

  },
  {
    job_id: "1646",
    title: "DevOps",
    location: "Bangalore, India",
    category: "Technology",
    time: JOB_TIME.FULL_TIME,
    status: JOB_STATUS.HIRING_ACTIVELY,
    // experience: "Exp: 2-4 years",
    description: "We are seeking a talented and proactive DevOps Engineer to join our innovative and fast-paced team.",
    image_url: TechIcon,
    JD: `
    <h2>About Ziroh Labs</h2>
    <br>
    <p>Ziroh Labs is a Data Security and Privacy Technology startup based in the Bay Area (California), Bangalore, and Canberra.</p>
    <br>
    <p>We work on core Computer Science topics, and we promise you will have a lot of fun. There’s no such thing as work at Ziroh Labs. We love writing code and are looking for those of you who also enjoy it. Compensation is, of course, at par with the industry, along with the perks of having an opportunity to read the best of the papers in computer science, discuss, present, total allowance to think radical and wild (we love failures!), and implementing tech that can change the lives of the people at large.</p>
    <br>
    <p>The scientific and technical team at Ziroh Labs includes award-winning computer scientists, cryptographers, PhDs, and developers in distributed computing, large-scale distributed storage systems, and algorithms. Ziroh Labs is a winner at Cisco Launchpad Cohort 3, Mass Challenge, Israel, Samsung Global Accelerator Program, and NASSCOM Deep Tech club members. Individuals will get opportunities to contribute in all different areas depending on the demand and requirement of the time.</p>
    <br></br> 
    <h2>Job Overview:</h2>
    <br>
    <p>We are seeking a talented and proactive DevOps Engineer to join our innovative and
    fast-paced team. In this role, you will bridge the gap between software development
    and IT operations to enhance the development lifecycle through automation,
    configuration management, and infrastructure management. You will be responsible
    for optimizing deployment processes, ensuring application uptime, and improving
    the scalability of our systems. As a DevOps Engineer, you will have the opportunity to
    work with cutting-edge tools and technologies to foster collaboration across teams
    and contribute to the smooth delivery of software products.</p>
    <br>
    <br>
    <h2>Responsibilities:</h2>
    <br>
    <ul>
    <li>Designing and developing our GCP/other cloud infrastructure using Terraform.</li>
    <li>Developing & managing the machine configurations using Ansible.</li>
    <li>Developing proactive alerts etc. using observability systems like Prometheus.</li>
    <li>Implement CI/CD pipelines using automation tools like Jenkins.</li>
    <li>Perform root cause analysis of production errors and resolve technical issues.</li>
    <li>Design procedures for system troubleshooting and maintenance.</li>
    <li>Identifying technical problems and developing software updates and fixes.</li>
    <li>Working with software developers and software engineers to ensure
    that development follows established processes and works as intended.</li>
    <li>Working on ways to automate and improve development and release processes.</li>
    <li>Design procedures for system troubleshooting and maintenance.</li>
    </ul>
    <br>
    </br>
    <h2>Requirements:</h2>
    <br>
    <ul>
    <li>Experience as a DevOps engineer or in a similar software engineering role.</li>
    <li>Proficiency with Git and GitHub workflows.</li>
    <li>Problem-solving attitude.</li>
    <li>Collaborative team spirit.</li>
    <li>Bachelor’s in computer science, engineering, or a relevant field Skills.</li>
    </ul>
    <br>
    </br>
    <h2>Skill:</h2>
    <br>
    <ul>
    <li>Ansible</li>
    <li>GCP</li>
    <li>Jenkins</li>
    <li>Kubernetes</li>
    <li>Python / Golang</li>
    <li>Terraform</li>
    </ul>
    <br>
    </br>
    <h2>Benefits:</h2>
    <br>
    <ul>
    <li>Competitive salary and performance bonus.</li>
    <li>Health insurance coverage for individual and family members.</li>
    <li>Opportunity to work on exciting problems and contribute to innovation.</li>
    <li>Work with a passionate and enthusiastic young team.</li>
    <li>Opportunity for rapid upskilling and career growth.</li>
    <li>Collaborative and inclusive startup work culture.</li>
    <li>5 days of Workcation to any destination outside India for best performers.</li>
    </ul>
    <br>
    </br>
    <h2>Experience:</h2>
    <br>
    <ul>
    <li>2-4 Years</li>
    </ul>
    <br></br>
    <h2>Interview Process:</h2>
    <br>
    <ul>
    <li>Meet and Greet</li>
    <li>Coding Round -1</li>
    <li>Coding Round -2</li>  
    <li>HR</li>

    </ul>
    <br></br>
    <h2>Expected time for Joining:</h2>
    <br>
    <ul>
    <li>Within 15-30 days post-selection.</li>
    </ul>
    `
  },
  {
    job_id: "1647",
    title: "AI Scientist (Time Series)",
    location: "Bangalore, India",
    Qualification: "PhD",
    category: "Technology",
    time: JOB_TIME.FULL_TIME,
    status: JOB_STATUS.HIRING_ACTIVELY,
    description: "Your responsibilities will include developing innovative AI technologies, conducting empirical research, and implementing AI solutions to real-world challenges.",
    image_url: TechIcon,
    JD: `
    <h2>About Ziroh Labs </h2>
    <br>
    <p>Ziroh Labs is a Data Security and Privacy Technology startup based in the Bay Area (California), Bangalore, and Canberra.</p>
    <br>
    <p>We work on core Computer Science topics, and we promise you will have a lot of fun. There’s no such thing as work at Ziroh Labs. We love writing code and are looking for those of you who also enjoy it. Compensation is, of course, at par with the industry, along with the perks of having an opportunity to read the best of the papers in computer science, discuss, present, total allowance to think radical and wild (we love failures!), and implementing tech that can change the lives of the people at large.</p>
    <br>
    <p>The scientific and technical team at Ziroh Labs includes award-winning computer scientists, cryptographers, PhDs, and developers in distributed computing, large-scale distributed storage systems, and algorithms. Ziroh Labs is a winner at Cisco Launchpad Cohort 3, Mass Challenge, Israel, Samsung Global Accelerator Program, and NASSCOM Deep Tech club members. Individuals will get opportunities to contribute in all different areas depending on the demand and requirement of the time.</p>
    <br></br> 
    <h2>Job Summary:</h2>
    <br>
    <p>As an AI Research Scientist, you will be at the forefront of shaping the
    future of artificial intelligence research and development. This role
    demands a blend of theoretical knowledge and practical expertise in AI,
    having a primary focus on NLP, computer vision, and time-series processing.
    Your responsibilities will include developing innovative AI technologies,
    conducting empirical research, and implementing AI solutions to real-world
    challenges.</p>
    <br>
    <p>You will have the unique opportunity to collaborate with leading researchers
    and industry experts, driving advancements in AI and making significant
    contributions to the field, thereby influencing future AI applications.</p>
    <br>
    </br>
    <h2>Requirements and skills:</h2>
    <br>
    <ul>
    <li>Conceptualise and execute groundbreaking time series research projects.</li>
    <li>Optimize the AI solutions for performance and scalability.</li>
    <li>Develop state-of-the-art algorithms and computational models.</li>
    <li>Conduct extensive testing and validation of AI systems.</li>
    <li>Collaborate with global research teams and industry partners.</li>
    <li>Publish and present research findings in top-tier journals and conferences.</li>
    <li>Stay abreast of emerging trends in AI research and technology.</li>
    <li>Mentor junior researchers and contribute to collaborative learning.</li>
    <li>Engage with stakeholders to identify research opportunities and directions.</li>
    </ul>
    <br>
    </br>
    <h2>Qualifications:</h2>
    <br>
    <ul>
    <li>PhD or equivalent experience in Computer Science, AI, or a related
    technical field.</li>
    <li>Demonstrable experience in AI research, with publications in renowned
    journals.</li>
    <li>Proficiency in programming languages such as Python, Java, or R.</li>
    <li>Deep understanding of machine learning, neural networks and
    computational statistics.</li>
    <li>Ability to think critically and creatively to tackle complex challenges.</li>
    <li>Strong collaboration and communication skills, capable of working
    effectively in diverse environments.</li>
    <li>Passion for continuous learning and adapting to new technological
    advancements.</li>
    </ul>
    <br>
    </br>
    <h2>Benefits:</h2>
    <br>
    <ul>
    <li>Competitive salary and performance bonus.</li>
    <li>Health insurance coverage for individual and family members.</li>
    <li>Opportunity to work on exciting problems and contribute to innovation.</li>
    <li>Work with a passionate and enthusiastic young team.</li>
    <li>Collaborative and inclusive startup work culture.</li>
    <li>Opportunity for rapid upskilling and career growth.</li>
    <li>5 days of Worcation to any destination outside India for best performers.</li>
    </ul>
    <br>
    </br>
    <h2>Qualification:</h2>
    <br>
    <ul>
    <li>PhD</li>
    </ul>
    <br></br>
    <h2>Interview Process:</h2>
    <br>
    <ul>
    <li>Meet and Greet</li>
    <li> Round -1</li>
    <li> Round -2</li>  
    <li>HR</li>

    </ul>
    <br></br>
    <h2>Expected time for Joining:</h2>
    <br>
    <ul>
    <li>Within 15-30 days post-selection.</li>
    </ul>

    `

  }
];